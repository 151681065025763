<template>
  <Layout :breadcrumb="breadcrumb">
    <div class="row" v-if="contrato.cancelado == 1">
      <div class="col-md-12 text-center text-danger">
        <div 
          style="display: table; position: absolute; left: 0px; top: 50px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
          <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
            C O N T R A T O &nbsp;&nbsp; C A N C E L A D O
          </div>
        </div>
        <div style="display: table; position: absolute; left: 0px; top: 650px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
          <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
            C O N T R A T O &nbsp;&nbsp; C A N C E L A D O
          </div>
        </div>
        <div style="display: table; position: absolute; left: 0px; top: 1250px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
          <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
            C O N T R A T O &nbsp;&nbsp; C A N C E L A D O
          </div>
        </div>
        <div style="display: table; position: absolute; left: 0px; top: 1850px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
          <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
            C O N T R A T O &nbsp;&nbsp; C A N C E L A D O
          </div>
        </div>
        <div style="display: table; position: absolute; left: 0px; top: 2450px; z-index: 99; font-size: 60px; width: 100%; height: 100%; ">
          <div style="margin: auto; width: 85%; margin-top: 20%; -ms-transform: rotate(-30deg); -webkit-transform: rotate(-30deg); transform: rotate(-30deg); ">
            C O N T R A T O &nbsp;&nbsp; C A N C E L A D O
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Contrato</h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!contrato.cancelado && !bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>ID en sistema</label>
                <input
                  type="text"
                  v-model="contrato.id"
                  class="form-control"
                  placeholder="1234"
                  readonly
                  :disabled="contrato.cancelado == 1"
                />
              </div>
              <div class="col-md-6">
                <label>ID de contrato físico <sup>* Opcional</sup></label>
                <input
                  type="text"
                  v-model="contrato.id_contrato_fisico"
                  class="form-control"
                  placeholder="1234"
                  :disabled="contrato.cancelado == 1"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Fecha de instalación</label>
                <Datepicker
                  ref="fecha_instalacion"
                  v-model="contrato.fecha_instalacion"
                  placeholder="dd/mm/aaaa"
                  class="form-control"
                  :class="{
                    'bg-white': $store.state.layout.layoutMode == 'light',
                  }"
                  :inputFormat="'dd/MM/yyyy'"
                  :disabled="contrato.cancelado == 1"
                />
              </div>
              <div class="col-md-6">
                <label>Termino de contrato <small>(opcional)</small> </label>
                <Datepicker
                  ref="fecha_vencimiento"
                  v-model="contrato.fecha_vencimiento"
                  placeholder="dd/mm/aaaa"
                  class="form-control"
                  :class="{
                    'bg-white': $store.state.layout.layoutMode == 'light',
                  }"
                  :inputFormat="'dd/MM/yyyy'"
                  :disabled="contrato.cancelado == 1"
                />
              </div>
            </div>
            <label>Nota</label>
            <textarea
              rows="4"
              class="form-control"
              v-model="contrato.nota"
              placeholder="Nota del contrato"
              :disabled="contrato.cancelado == 1"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Archivos del contrato</h4>
            <div>
              <button class="btn btn-outline-success btn-xs" @click="seleccionarArchivos()">
                <i class="mdi mdi-file-upload-outline"></i>
                Subir archivo(s)
              </button>
            </div>
          </div>
          <div class="card-body" style="height: 305px;">
            <SimpleBar
              class="table-responsive"
              data-simplebar
              style="max-height: 265px;"
            >
              <table class="table table-hover table-striped table-nowrap align-middle" id="tabla-resumen">
                <thead>
                  <tr>
                    <th style="width: 50px;">No.</th>
                    <th>Nombre</th>
                    <th style="width: 100px;">Tamaño</th>
                    <th class="text-center" style="width: 150px;">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(archivo, index) in archivos" :key="index">
                    <td class="fw-semibold">
                      {{  index+1 }}
                    </td>
                    <td>
                      <p class="text-truncate mt-2" :title="archivo.nombre">
                        {{ archivo.nombre }}
                      </p>
                    </td>
                    <td class="fw-semibold">
                      {{ calcularSize(archivo.size) }}
                    </td>
                    <td>
                      <div class="btn-group">
                        <button class="btn btn-outline-info btn-sm" title="Vista previa del archivo" @click="visualizarArchivo(archivo)">
                          <i class="mdi mdi-file-eye-outline"></i>
                        </button>
                        <button class="btn btn-outline-primary btn-sm" title="Descargar archivo" @click="descargarArchivo(archivo)">
                          <i class="mdi mdi-file-download-outline"></i>
                        </button>
                        <button class="btn btn-outline-warning btn-sm" title="Editar el nombre del archivo" @click="editarArchivo(archivo)">
                          <i class="mdi mdi-file-edit-outline"></i>
                        </button>
                        <button class="btn btn-outline-danger btn-sm" title="Eliminar archivo" @click="eliminarArchivo(archivo)">
                          <i class="mdi mdi-file-remove-outline"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Servicio de internet</h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  onclick="return false;"
                  v-show="!contrato.cancelado && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label>Servicio</label>
            <select
              class="form-select"
              name="id_servicio"
              ref="id_servicio"
              v-model="contrato.id_servicio"
              @change="actualizarServicioSeleccionado()"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="servicio in servicios"
                :key="'servicio-'+servicio.id"
                :value="servicio.id"
              >
                {{ servicio.nombre }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Precio</label>
            <div class="input-group">
              <span class="input-group-text">
                {{ monedaSistema.simbolo }}
              </span>
              <input
                type="text"
                class="form-control text-right"
                v-model="contrato.precio_servicio"
                placeholder="0"
                readonly
                :disabled="contrato.cancelado == 1"
              />
              <span class="input-group-text">
                {{ monedaSistema.codigo }}
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <label>DW / UP</label>
            <input
              type="text"
              v-model="contrato.carga_descarga_servicio"
              class="form-control"
              readonly
              :disabled="contrato.cancelado == 1"
            />
          </div>

          <div class="col-md-3 col-xs-6">
            <label>Cargos por morosidad</label> <br />
            <div class="form-check form-switch form-switch-lg mb-3">
              <input
                v-model="contrato.cargo_morosidad"
                class="form-check-input"
                type="checkbox"
                id="cargosPorMorosidad"
                :disabled="contrato.cancelado == 1"
              />
              <label class="form-check-label" for="cargosPorMorosidad">
                <!-- {{ contrato.cargo_morosidad ? 'Aplicar' : 'No aplicar' }} -->
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label>Tipo de renta</label>
            <select
              class="form-select"
              v-model="contrato.id_tipo_renta"
              @change="tipoRentaActualizada()"
              :disabled="contrato.cancelado == 1"
            >
              <option :value="1">Mensual fija</option>
              <option :value="2">Mensual variable</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Modalidad del servicio</label>
            <select
              class="form-select"
              v-model="contrato.prepago"
              :disabled="
                contrato.id_tipo_renta == 2 || contrato.cancelado == 1
              "
            >
              <option :value="1">Prepago</option>
              <option :value="0">Pospago</option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Modo para generar facturas</label>
            <select
              class="form-select"
              v-model="contrato.modo_generar_facturas"
              :disabled="
                contrato.id_tipo_renta == 2 || contrato.cancelado == 1
              "
            >
              <option :value="1">
                1. Genera las facturas cuando las previas han sido pagadas y 
                toma de referencia el fin del periodo del contrato.
              </option>
              <option :value="2">
                2. Un día específico de cada mes en función del día de pago, 
                sin importar si las facturas previas han sido pagadas.
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">Periodos del servicio de internet</h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  onclick="return false;"
                  v-show="!contrato.cancelado && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <label>Dia inicial del periodo</label>
            <select
              v-model="contrato.dia_inicio_periodo"
              class="form-select"
              :disabled="contrato.cancelado == 1"
              v-if="contrato.id_tipo_renta == 1"
            >
              <option v-for="n in 31" :key="'dias-mes-'+n" :value="n">
                Día {{ n }} del mes
              </option>
            </select>

            <div v-if="contrato.id_tipo_renta == 2">
              <input
                type="text"
                class="form-control"
                value="Indefinido"
                readonly
              />
            </div>
          </div>
          <span class="d-block d-lg-none">
            <div class="col-md-6"></div>
          </span>
          <div class="col-lg-3 col-md-6">
            <label>Inicio del periodo</label>
            <Datepicker
              ref="inicio_periodo"
              v-model="contrato.inicio_periodo"
              placeholder="dd/mm/aaaa"
              class="form-control"
              :class="{
                'bg-white': $store.state.layout.layoutMode == 'light',
              }"
              :inputFormat="'dd/MM/yyyy'"
              :disabled="modo != 'nuevo'"
            />
          </div>

          <div class="col-lg-3 col-md-6">
            <label>Fin del periodo</label>
            <Datepicker
              ref="fin_periodo"
              v-model="contrato.fin_periodo"
              placeholder="dd/mm/aaaa"
              class="form-control"
              :class="{
                'bg-white': $store.state.layout.layoutMode == 'light',
              }"
              :inputFormat="'dd/MM/yyyy'"
              :disabled="modo != 'nuevo'"
            />
          </div>

          <div class="col-lg-3 col-md-12" v-show="modo != 'nuevo'">
            <label>Opciones del periodo</label>
            <div class="row">
              <div class="col-sm-6 d-grid gap-2">
                <button
                  class="btn btn-outline-success bg-gradient font-size-15"
                  title="Cambiar peridodo"
                  @click="mostrarMdlCambiarPerido()"
                >
                  <i class="mdi mdi-calendar"></i>
                  Cambiar
                </button>
              </div>
              <div class="col-sm-6 d-grid gap-2">
                <button
                  class="btn btn-outline-info bg-gradient font-size-15"
                  title="Historial de cambios"
                  @click="mostrarMdlHistorialPeriodos()"
                >
                  <i class="mdi mdi-history"></i> Historial
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6">
            <label>Fecha de pago</label>
            <div v-show="contrato.id_tipo_renta == 1">
              <select
                class="form-select"
                v-model="contrato.dia_pago"
                :disabled="contrato.cancelado == 1 || contrato.id_tipo_renta == 2"
              >
                <option :value="0">
                  {{'Mismo día del fin de periodo'}}
                </option>
                <option v-for="n in 27" :key="'dia-'+n" :value="n">
                  {{ n }} 
                  {{`día${n>1?'s':''} después del fin de periodo`}}
                </option>
              </select>
              <input type="text"
                :value="fecha_pago"
                class="form-control"
                readonly>
            </div>
            <div v-show="contrato.id_tipo_renta == 2">
              <input type="text"
                :value="formatoFecha(contrato.fin_periodo)"
                class="form-control"
                readonly>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label>Fecha normal de corte del servicio</label>
            <div v-show="contrato.id_tipo_renta == 1">
              <select
                class="form-select"
                v-model="contrato.n_dias_corte"
                :disabled="contrato.cancelado == 1"
                v-if="contrato.id_tipo_renta == 1"
              >
                <option value="0">El mismo día de pago</option>
                <option v-for="n in 15" :key="'dia-despues-'+n" :value="n">
                  {{ n }} día{{ n > 1 ? 's' : '' }} después del día de pago
                </option>
              </select>
              <div v-if="contrato.id_tipo_renta == 2">
                <input
                  type="text"
                  class="form-control"
                  value="Día siguiente al final del periodo"
                  readonly
                />
              </div>
              <input type="text"
                :value="fecha_corte_servicio"
                class="form-control"
                readonly>
            </div>
            <div v-show="contrato.id_tipo_renta == 2">
              <input type="text"
                :value="formatoFecha(contrato.fin_periodo)"
                class="form-control"
                readonly>
            </div>
          </div>
          <div class="col-lg-3 col-md-6" v-show="contrato.prorroga > 0">
            <label>Fecha de corte del servicio con promesa de pago</label>
            <div v-show="contrato.id_tipo_renta == 1">
              <input type="text"
                :value="`${contrato.n_dias_corte} días + ${contrato.prorroga} días de prorroga`"
                class="form-control"
                readonly>
              <input type="text"
                :value="fecha_corte_servicio_con_prorroga"
                class="form-control"
                readonly>
            </div>
            <div v-show="contrato.id_tipo_renta == 2">
              <input type="text"
                :value="contrato.fin_periodo"
                class="form-control"
                readonly>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label>Crear factura</label>
            <select
              class="form-select"
              v-model="contrato.n_dias_generar_factura"
              :disabled="contrato.cancelado == 1"
            >
              <option :value="0">
                <template v-if="contrato.id_tipo_renta == 1">
                  Generar el mismo día de pago
                </template>
                <template v-if="contrato.id_tipo_renta == 2">
                  Generar al final del periodo
                </template>
              </option>
              <option v-for="n in 20" :key="'dia-antes-'+n" :value="n">
                {{ n }} día{{ n > 1 ? 's' : '' }}
                <template v-if="contrato.id_tipo_renta == 1">
                  antes del día de pago
                </template>
                <template v-if="contrato.id_tipo_renta == 2">
                  antes del final del periodo
                </template>
              </option>
            </select>
            <input type="text"
              :value="fecha_generar_nueva_factura"
              class="form-control"
              readonly>
          </div>

          <div class="col-md-3 col-xs-6">
            <label>Notificacion por correo</label>
            <div class="form-check form-switch form-switch-lg mb-3">
              <input
                v-model="contrato.notificacion_correo"
                class="form-check-input"
                type="checkbox"
                id="notificacionPorCorreo"
                :disabled="contrato.cancelado == 1"
              />
              <label class="form-check-label" for="notificacionPorCorreo">
                <!-- {{ contrato.notificacion_correo ? 'Aplicar' : 'No aplicar' }} -->
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">CPE <small>Equipo local del cliente</small></h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!contrato.cancelado && !bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <router-link
                  :to="{
                    name: 'edicionCpe',
                    params: { id: contrato.id_cpe }
                  }"
                  v-if="cpeSeleccionado.id != null"
                >
                  <img
                    :src="
                      `${API}/cpes/${cpeSeleccionado.id}/imagen?tipo_foto=150x150&_tk=${tk}`
                    "
                    alt="imagen del cpe"
                    v-if="cpeSeleccionado.id != null"
                    class="img-fluid img-thumbnail rounded"
                  />
                </router-link>
                <img
                  :src="
                    `${API}/cpes/modelos/${cpeSeleccionado.id_modelo}/imagen?tipo_foto=150x150&_tk=${tk}`
                  "
                  alt="imagen del cpe"
                  v-if="cpeSeleccionado.id == null && cpeSeleccionado.id_modelo != null"
                  class="img-fluid img-thumbnail rounded"
                />
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <label>Selección de CPE</label>
                    <select
                      class="form-select"
                      name="id_cpe"
                      ref="id_cpe"
                      v-model="contrato.id_cpe"
                      @change="actualizarCpeSeleccionado()"
                      :disabled="contrato.cancelado == 1"
                    >
                      <option :value="null">Ninguno</option>
                      <option :value="-1">Nuevo CPE</option>
                      <option
                        v-for="cpe in cpes"
                        :key="'cpe-disponible-'+cpe.id"
                        :value="cpe.id"
                      >
                        {{ cpe.nombre }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label>Estado</label>
                    <select
                      v-model="cpeSeleccionado.id_estado"
                      name="id_estado"
                      ref="id_estado"
                      class="form-select"
                      :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                      @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="estado in estados_cpes"
                        :value="estado.id"
                        :key="'estado-cpe-'+estado.id"
                      >
                        {{ estado.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Marca</label>
                    <select
                      v-model="cpeSeleccionado.id_marca"
                      @change="
                        cpeSeleccionado.id_modelo = null,
                        contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                        cargarModelosCpes()
                      "
                      ref="id_marca"
                      class="form-select"
                      :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="marca in marcas_cpes"
                        :value="marca.id"
                        :key="'marca-cpe-'+marca.id"
                      >
                        {{ marca.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Modelo</label>
                    <select
                      v-model="cpeSeleccionado.id_modelo"
                      ref="id_modelo"
                      class="form-select"
                      :disabled="contrato.cancelado == 1 || modelos_cpes.length == 0 || contrato.id_cpe == null"
                      @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="modelo in modelos_cpes"
                        :value="modelo.id"
                        :key="'modelo-cpe-'+modelo.id"
                      >
                        {{ modelo.identificador }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Nombre del CPE</label>
                    <input
                      v-model="cpeSeleccionado.nombre"
                      ref="nombre"
                      class="form-control"
                      name="nombre"
                      placeholder="Nombre del CPE"
                      :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                      @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                    />
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br>

            <h5>Router de administración</h5>
            <hr>

            <div class="row">
              <div class="col-md-6">
                <label>Router</label>
                <select
                  ref="id_router"
                  class="form-select"
                  v-model="contrato.id_router"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cpeSeleccionado.id_router = contrato.id_router,
                    actualizarIspsRouter(),
                    cargarServiciosPPPoE(),
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                  :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                >
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="router in routers"
                    :key="'router-'+router.id"
                    :value="router.id"
                  >
                    {{ router.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>Proveedor de internet</label>
                <select
                  class="form-select"
                  name="id_isp"
                  ref="id_isp"
                  v-model="contrato.id_isp"
                  :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cpeSeleccionado.id_isp = contrato.id_isp
                  "
                >
                  <option :value="null">Seleccionar</option>
                  <option v-for="isp in isps" :key="'isp-'+isp.id" :value="isp.id">
                    {{ isp.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Conectado a sitio</label>
                <select
                  class="form-select"
                  name="id_sitio"
                  ref="id_sitio"
                  v-model="contrato.id_sitio"
                  :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cpeSeleccionado.id_sitio = contrato.id_sitio
                  "
                >
                  <option :value="null">Sitio del router</option>
                  <option
                    v-for="sitio in sitios"
                    :key="'sitio-'+sitio.id"
                    :value="sitio.id"
                  >
                    {{ sitio.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mt-4 d-grid gap-2">
                <button
                  class="btn btn-outline-info bg-gradient"
                  @click="mostrarMdlThroughPut(contrato.id_cpe)"
                  v-show="
                    modo == 'edicion' &&
                    contrato.id_cpe != null &&
                    contrato.id_cpe != -1 &&
                    contrato.cancelado != 1
                  "
                >
                  <strong>
                    <i class="mdi mdi-chart-bell-curve-cumulative font-size-15"></i>
                    Throughput
                  </strong>
                </button>
              </div>
            </div>
            
            <div class="row" v-if="cpeSeleccionado.id_router != null">
              <div class="col-md-6">
                <h5>Puertas de enlace disponibles</h5>
                <template v-for="(puerta, index) in puertas_enlace">
                  <span class="badge font-size-15 badge-pill badge-soft-primary" v-if="true" :key="'puerta-'+index">
                    {{ puerta }}
                  </span>
                  &nbsp;
                </template>
                <span
                  v-if="puertas_enlace.length == 0"
                  class="badge font-size-15 bg-warning bg-gradient"
                >
                  Ninguna
                </span>
              </div>
              <div class="col-md-6">
                <h5>Servicios PPPoE disponibles</h5>
                <template v-for="(servicio, index) in serviciosPPPoE">
                  <span class="badge font-size-15 badge-pill badge-soft-primary" v-if="true" :key="'ppoe-'+index">
                    {{ servicio.nombre }}
                  </span>
                  &nbsp;
                </template>
                <span
                  v-if="serviciosPPPoE.length == 0"
                  class="badge font-size-15 bg-warning bg-gradient"
                >
                  Ninguno
                </span>
              </div>
            </div>

            <br>
            <h5>Administración</h5>
            <hr>

            <div class="row">
              <div class="col-md-6">
                <label>Modo de administración</label>
                <select
                  class="form-select"
                  ref="pppoe"
                  v-model="cpeSeleccionado.pppoe"
                  :disabled="contrato.cancelado == 1 || contrato.id_cpe == null"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cargarServiciosPPPoE(),
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                >
                  <option :value="0">Por dirección IP estática</option>
                  <option :value="1">Por credencial PPPoE</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Dirección IP de WAN</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.ip_wan"
                  ref="ip_wan"
                  placeholder="172.16.1.X"
                  class="form-control"
                  v-show="cpeSeleccionado.pppoe == false"
                  @change="
                    contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : '',
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
                <input
                  type="text"
                  class="form-control"
                  :value="'- - Automática - -'"
                  readonly
                  disabled
                  v-show="cpeSeleccionado.pppoe == true"
                />
              </div>
              <div class="col-md-6">
                <label>Dirección MAC de WAN</label>
                <input
                  type="text"
                  ref="mac_wan"
                  v-model="cpeSeleccionado.mac_wan"
                  maxlength="17"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  class="form-control"
                  placeholder="MAC del puerto WAN en el CPE"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Dirección IP de LAN</label>
                <input
                  type="text"
                  ref="ip_lan"
                  v-model="cpeSeleccionado.ip_lan"
                  class="form-control"
                  placeholder="192.168.100.1"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
              <div class="col-md-6">
                <label>Dirección MAC de LAN</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.mac_lan"
                  ref="mac_lan"
                  class="form-control"
                  placeholder="MAC del puerto LAN en el CPE"
                  maxlength="17"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
            </div>
            <div class="row" v-show="cpeSeleccionado.pppoe == true">
              <div class="col-md-6">
                <label>Usuario PPPoE</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.usuario_pppoe"
                  ref="usuario_pppoe"
                  class="form-control"
                  placeholder="Usuario PPPoE"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
              <div class="col-md-6">
                <label>Clave PPPoE</label>
                <input
                  type="text"
                  v-model="cpeSeleccionado.clave_pppoe"
                  ref="clave_pppoe"
                  class="form-control"
                  placeholder="Clave PPPoE"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
            </div>

            <br>
            <h5>Credencial de administración</h5>
            <hr>
            
            <div class="row">
              <div class="col-md-6">
                <label>Usuario</label>
                <input
                  type="text"
                  placeholder="Usuario"
                  v-model="cpeSeleccionado.usuario"
                  class="form-control"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
              <div class="col-md-6">
                <label>Clave</label>
                <input
                  type="text"
                  placeholder="Clave"
                  v-model="cpeSeleccionado.clave"
                  class="form-control"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                />
              </div>
            </div>
            <br>
            <h5>Control del servicio de internet</h5>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <label>Control automático</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="!contrato.control_manual_cpe"
                    @change="contrato.control_manual_cpe = !contrato.control_manual_cpe"
                    class="form-check-input"
                    type="checkbox"
                    id="controlAutomatico"
                    :disabled="contrato.cancelado == 1"
                  />
                  <label class="form-check-label" for="controlAutomatico"></label>
                </div>
              </div>
              <div class="col-md-4">
                <label>Acceso a internet</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    v-model="contrato.enlace_activo_cpe"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="contrato.cancelado == 1 || !contrato.control_manual_cpe"
                    id="enlaceActivo"
                  />
                  <label class="form-check-label" for="enlaceActivo"></label>
                </div>
              </div>
            </div>

            <br>
            <h5>Información complementaria</h5>
            <hr>

            <div class="row">
              <div class="col-md-12">
                <label>Nota de información complementaria para el CPE</label>
                <textarea
                  v-model="cpeSeleccionado.nota"
                  class="form-control"
                  name="nota"
                  ref="nota"
                  rows="10"
                  maxlength="2000"
                  :readonly="contrato.cancelado == 1 || contrato.id_cpe == null"
                  @change="contrato.id_cpe != null && contrato.id_cpe != -1 ? actualizarCpe = true : ''"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">DH <small>Dispositivo de hogar</small></h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      onclick="return false;"
                      v-show="!contrato.cancelado && !bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <router-link
                  :to="{
                    name: 'edicionDh',
                    params: { id: contrato.id_dh }
                  }"
                  v-if="dhSeleccionado.id != null && contrato.id_dh != null"
                >
                  <img
                    :src="`${API}/dhs/${dhSeleccionado.id}/foto?tipo_foto=150x150&_tk=${tk}`"
                    alt="foto del DH"
                    v-if="dhSeleccionado.id != null"
                    class="img-fluid img-thumbnail rounded"
                  />
                </router-link>
                <img
                  :src="
                    `${API}/dhs/modelos/${dhSeleccionado.id_modelo}/imagen?tipo_foto=150x150&_tk=${tk}`
                  "
                  alt="imagen del dh"
                  v-if="dhSeleccionado.id == null && dhSeleccionado.id_modelo != null"
                  class="img-fluid img-thumbnail rounded"
                />
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <label>Selección de DH</label>
                    <select
                      class="form-select"
                      name="id_dh"
                      v-model="contrato.id_dh"
                      @change="actualizarDhSeleccionado()"
                      :disabled="contrato.cancelado == 1"
                    >
                      <option :value="null">Seleccionar</option>
                      <option :value="-1">Nuevo DH</option>
                      <option
                        v-for="(dh, index) in dhs"
                        :key="`dh-disponible-${index}`"
                        :value="dh.id"
                        v-show="
                          dh.ocupado == false || dh.id == contrato.id_dh
                        "
                      >
                        {{ dh.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Estado</label>
                    <select
                      name="id_estado"
                      class="form-select"
                      v-model="dhSeleccionado.id_estado"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :disabled="contrato.cancelado == 1 || contrato.id_dh == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="estado in estados_dh"
                        :value="estado.id"
                        :key="'estado-dh-'+estado.id"
                      >
                        {{ estado.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label>Tipo</label>
                    <select
                      name="id_tipo"
                      class="form-select"
                      id="imgMarca"
                      v-model="dhSeleccionado.id_tipo"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :disabled="contrato.cancelado == 1 || contrato.id_dh == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="tipo in tipos_dh"
                        :key="'tipo-dh-'+tipo.id"
                        :value="tipo.id"
                      >
                        {{ tipo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label>Marca</label>
                    <select
                      name="id_marca"
                      class="form-select"
                      v-model="dhSeleccionado.id_marca"
                      :disabled="contrato.cancelado == 1 || contrato.id_dh == null"
                      @change="
                        dhSeleccionado.id_modelo = null,
                        contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : '',
                        cargarModelosDH()
                      "
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="marca in marcas_dh"
                        :key="'marca-dh-'+marca.id"
                        :value="marca.id"
                      >
                        {{ marca.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label>Modelo</label>
                    <select
                      name="id_modelo"
                      class="form-select"
                      v-model="dhSeleccionado.id_modelo"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :disabled="contrato.cancelado == 1 || modelos_dh.length == 0 || contrato.id_dh == null"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="modelo in modelos_dh"
                        :key="'modelo-dh-'+modelo.id"
                        :value="modelo.id"
                      >
                        {{ modelo.identificador }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label>Nombre</label>
                    <input
                      class="form-control"
                      name="nombre"
                      placeholder="Nombre del DH"
                      v-model="dhSeleccionado.nombre"
                      @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                      :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                    />
                  </div>
                </div>
              </div>
            </div>

            <br>
            <h5>Información de red</h5>
            <hr>

            <div>
              <div class="row">
                <div class="col-md-6">
                  <label>Dirección IP de WAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.ip_wan"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="192.168.100.2"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Dirección MAC de WAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.mac_wan"
                    maxlength="17"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="MAC del puerto WAN en el DH"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label>Dirección IP de LAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.ip_lan"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="192.168.200.1"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Dirección MAC de LAN</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.mac_lan"
                    maxlength="17"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="MAC del puerto LAN en el DH"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
              </div>

              <br>
              <h5>Credencial de administración</h5>
              <hr>

              <div class="row">
                <div class="col-md-6">
                  <label>Usuario</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.usuario"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="Usuario"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Clave</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.clave"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="Clave"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
              </div>

              <br />
              <h5>Configuración WIFI</h5>
              <hr>
              
              <div class="row">
                <div class="col-md-6">
                  <label>SSID</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.ssid"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="SSID"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
                <div class="col-md-6">
                  <label>Clave</label>
                  <input
                    type="text"
                    v-model="dhSeleccionado.clave_wifi"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    class="form-control"
                    placeholder="Clave WIFI"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  />
                </div>
              </div>

              <br />
              <h5>Información complementaria</h5>
              <hr>

              <div class="row">
                <div class="col-md-12">
                  <label>Nota del dispositivo</label>
                  <textarea
                    class="form-control"
                    name="nota"
                    rows="10"
                    maxlength="2000"
                    placeholder="Información complementaria"
                    v-model="dhSeleccionado.nota"
                    @change="contrato.id_dh != null && contrato.id_dh != -1 ? actualizarDh = true : ''"
                    :readonly="contrato.cancelado == 1 || contrato.id_dh == null"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ubicación del servicio
          <div class="form-check form-switch form-switch-lg ms-4 d-inline-block">
            <input
              v-model="mostrarMapa"
              class="form-check-input"
              type="checkbox"
              id="mostrarMapa"
            />
            <label class="form-check-label" for="mostrarMapa">Mapa</label>
          </div>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false;"
                  @click="usarDireccionCliente()"
                >
                  <i class="mdi mdi-directions-fork text-info"></i>
                  Usar dirección del cliente
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  v-if="!contrato.cancelado && !bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a class="dropdown-item" href="#" onclick="return false;" @click="cerrar()">
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="mostrarMapa">
          <GoogleMap
            ref="mapa"
            :api-key="apikeyGoogle"
            :center="posicionInicial"
            style="width: 100%; height: 371px;"
            :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
            :zoom="mapaZoom"
          >
            <Marker
              ref="marker"
              :options="{ 
                position: posicionInicial,
                icon: markerIcon,
                draggable: contrato.cancelado ? false : true,
              }"
              @dragend="mostrarPosicion($event)"
            ></Marker>
          </GoogleMap>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text">
                  <span class="d-none d-sm-block">
                    Latitud,Longitud
                  </span>
                  <span class="d-block d-sm-none">
                    Lat,Lng
                  </span>
                </span>
                <input
                  type="text"
                  class="form-control text-right"
                  v-model="lat_lng"
                  :readonly="bloquear_lat_lng"
                  :disabled="contrato.cancelado == 1"
                  @change="actualizarPosicion()"
                />
                <button class="btn btn-light" 
                  @click="bloquear_lat_lng = !bloquear_lat_lng"
                >
                  <i
                    class="mdi"
                    :class="{
                      'mdi-lock-outline': bloquear_lat_lng,
                      'mdi-lock-open-variant-outline': !bloquear_lat_lng
                    }"
                  ></i>
                </button>
              </div>
            </div>
          </div>
          <br />
        </div>
        <div class="row">
          <div class="col-md-6" v-show="tieneAlias('Calle')">
            <label>{{cargarAliasCampo('Calle')}}</label>
            <input
              class="form-control"
              name="calle"
              v-if="tieneOpciones('Calle')"
              v-model="contrato.calle"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="calle"
              v-if="!tieneOpciones('Calle')"
              v-model="contrato.calle"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Calle')"
                :key="`opcion-calle-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('N° ext')">
            <label>{{cargarAliasCampo('N° ext')}}</label>
            <input
              class="form-control"
              name="numero_exterior"
              v-if="tieneOpciones('N° ext')"
              v-model="contrato.numero_exterior"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="numero_exterior"
              v-if="!tieneOpciones('N° ext')"
              v-model="contrato.numero_exterior"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('N° ext')"
                :key="`opcion-numero-exterior-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('N° int')">
            <label>{{cargarAliasCampo('N° int')}}</label>
            <input
              class="form-control"
              name="numero_interior"
              v-if="tieneOpciones('N° int')"
              v-model="contrato.numero_interior"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="numero_interior"
              v-if="!tieneOpciones('N° int')"
              v-model="contrato.numero_interior"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('N° int')"
                :key="`opcion-numero-interior-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" v-show="tieneAlias('Colonia')">
            <label>{{cargarAliasCampo('Colonia')}}</label>
            <input
              class="form-control"
              name="colonia"
              v-if="tieneOpciones('Colonia')"
              v-model="contrato.colonia"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="colonia"
              v-if="!tieneOpciones('Colonia')"
              v-model="contrato.colonia"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Colonia')"
                :key="`opcion-colonia-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-5" v-show="tieneAlias('Delegación o Municipio')">
            <label>{{cargarAliasCampo('Delegación o Municipio')}}</label>
            <input
              class="form-control"
              name="municipio"
              v-if="tieneOpciones('Delegación o Municipio')"
              v-model="contrato.municipio"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="municipio"
              v-if="!tieneOpciones('Delegación o Municipio')"
              v-model="contrato.municipio"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Delegación o Municipio')"
                :key="`opcion-minicipio-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('Código postal')">
            <label>{{cargarAliasCampo('Código postal')}}</label>
            <input
              class="form-control"
              name="codigo_postal"
              v-if="tieneOpciones('Código postal')"
              v-model="contrato.codigo_postal"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="codigo_postal"
              v-if="!tieneOpciones('Código postal')"
              v-model="contrato.codigo_postal"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Código postal')"
                :key="`opcion-codigo-postal-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" v-show="tieneAlias('Estado de residencia')">
            <label>{{cargarAliasCampo('Estado de residencia')}}</label>
            <input
              class="form-control"
              name="estado_residencia"
              v-if="tieneOpciones('Estado de residencia')"
              v-model="contrato.estado_residencia"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="estado_residencia"
              v-if="!tieneOpciones('Estado de residencia')"
              v-model="contrato.estado_residencia"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('Estado de residencia')"
                :key="`opcion-estado-residencia-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3" v-show="tieneAlias('País')">
            <label>{{cargarAliasCampo('País')}}</label>
            <input
              class="form-control"
              name="pais"
              v-if="tieneOpciones('País')"
              v-model="contrato.pais"
              :disabled="contrato.cancelado == 1"
            />
            <select
              class="form-select"
              name="pais"
              v-if="!tieneOpciones('País')"
              v-model="contrato.pais"
              :disabled="contrato.cancelado == 1"
            >
              <option
                v-for="(opcion,index) in cargarOpciones('País')"
                :key="`opcion-pais-${index+1}`"
              >
                {{opcion.nombre}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <pnl-facturacion
      :modo="modo"
      :servicio-principal="servicioSeleccionado"
      :precio-servicio-principal="contrato.precio_servicio"
      :contrato-cancelado="contrato.cancelado"
      :servicios-complementarios="contrato.servicios_complementarios"
      @update:servicios-complementarios="contrato.servicios_complementarios = $event"
      :dhs="dhs"
      @update:dhs="dhs = $event"
      :piva="piva"
      :bandera_spinner="bandera_spinner"
      v-on:dh-ocupado="ocuparDh($event)"
      v-on:dh-desocupado="desocuparDh($event)"
      v-on:guardar="guardar()"
      v-on:actualizar="actualizar()"
      v-on:cerrar="cerrar()"
    ></pnl-facturacion>
    <div class="text-right">
      <div class="btn-group">
        <button
          class="btn btn-outline-danger"
          @click="preguntaCancelarContrato()"
          v-if="modo == 'edicion' && !contrato.cancelado"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-cancel"></i>
            Cancelar contrato
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-cancel"></i>
          </span>
        </button>
        <button class="btn btn-secondary" @click="atras()">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
          v-if="!contrato.cancelado"
          :disabled="bandera_spinner"
        >
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
        </button>
      </div>
    </div>
    <mdl-cambiar-periodo ref="mdlCamPer" @periodo:actualizado="actualizarContrato($event)"></mdl-cambiar-periodo>
    <mdl-historial-periodos ref="mdlHistPer"></mdl-historial-periodos>
    <mdl-through-put ref="mdlthroughput" v-if="$store.state.todo.nombre_empresa != 'Livaur Internet 2'"></mdl-through-put>
    <mdl-through-put-nuevo ref="mdlthroughput" v-if="$store.state.todo.nombre_empresa == 'Livaur Internet 2'"></mdl-through-put-nuevo>

    <mdl-visor-archivo ref="mdlVisorArchivo"></mdl-visor-archivo>
    <br>
  </Layout>
</template>

<script src="./EdicionContrato.js"></script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
.text-truncate {
  width: 400px;
}
</style>
